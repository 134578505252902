import { useState } from "react";
import { data } from "../../header.data";
import cn from "classnames";
import { BurgerMenu } from "./BurgerMenu";
export const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="overflow-hidden ">
      <div
        className={cn(
          "sm:relative fixed z-40 w-full h-full sm:bg-transparent bg-black/90 top-0 transition-all",
          {
            ["-right-full"]: !isOpen,
            ["right-0"]: isOpen,
          }
        )}
      >
        <nav className="flex gap-10 flex-col sm:flex-row mt-40 sm:mt-0 px-14 sm:px-0 ">
          {data.map((el) => (
            <a
              className="text-xl sm:text-sm font-medium uppercase hover:opacity-60 transition-opacity"
              href={el.link}
              key={el.title}
            >
              {el.title}
            </a>
          ))}
        </nav>
      </div>
      <BurgerMenu onClick={() => setIsOpen((prev) => !prev)} isOpen={isOpen} />
    </div>
  );
};
