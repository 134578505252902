import { Fade } from "react-reveal";
import bg_03 from "../../../../public/bg_03.png";
import ellipse from "../../../../public/ellipse.png";

export const Scenario = () => {
  return (
    <section className="pt-40 pb-20 overflow-hidden">
      <div
        style={{
          backgroundImage: `url(${bg_03})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
        }}
      >
        <div className=" max-w-container mx-auto px-5">
          <Fade>
          <div className="mb-40 max-w-xl text-center mx-auto">
            <div className="text-5xl font-extrabold mb-5">Economic Scenario</div>
            <div>
              Ipsum dolor sit amet consectetur adipiscing elit ut aliquam purus. Quis vel eros donec
              ac odio tempor. Donec ac odio tempor orci dapibus ultrices in iaculis nunc. Mi eget
              mauris pharetra et.{" "}
            </div>
          </div>
          </Fade>
     
          <div className="relative max-w-xl mx-auto ">
            <div className="font-medium bg-gradient-to-br relative z-10 rounded-2xl from-[#D9D9D9]/80 to-[#D9D9D9]/0  backdrop-blur-sm p-4 sm:p-8 lg:p-14">
             <Fade top>
             <div className="text-xl sm:text-4xl font-extrabold border-b text-center border-white pb-4 sm:pb-10">
                Scenario Title
              </div>
             </Fade>
            
              <div className="py-5 flex justify-between border-b border-white items-center">
                <div>Total Equity:</div>
                <div>
                  13,000,000<span className="text-[#55BBC8]">$</span>
                </div>
              </div>
              <div className="py-5 flex justify-between border-b border-white items-center">
                <div>Cost Estimation:</div>
                <div>
                  37,000,000<span className="text-[#55BBC8]">$</span>
                </div>
              </div>
              <div className="py-5 flex justify-between border-b border-white items-center">
                <div>Development:</div>
                <div>
                  10,000,000<span className="text-[#55BBC8]">$</span>
                </div>
              </div>
              <div className="py-5 flex justify-between border-b border-white items-center">
                <div>Minted:</div>
                <div>450/900</div>
              </div>
              <div className="py-10 sm:text-base text-sm">
                Elit duis tristique sollicitudin nibh. Vel elit scelerisque mauris pellentesque.
                Egestas tellus rutrum tellus pellentesque eu tincidunt. Amet consectetur adipiscing
                elit ut aliquam purus sit amet. Integer feugiat scelerisque varius morbi enim nunc
                faucibus a pellentesque.
              </div>
              {/* <button className="bg-[#55BBC8] hover:bg-[#55BBC8]/70 transition-all w-full py-4 font-bold rounded-full">
                Mint Now
              </button> */}
            </div>
            <img
              className="absolute z-0 bottom-0 translate-x-1/2 translate-y-1/2 right-0"
              src={ellipse}
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
};
