export const data = [
  {
    title: "about",
    link: "/",
  },
  {
    title: "projects",
    link: "/",
  },
  {
    title: "how it works",
    link: "/",
  },
  {
    title: "blog",
    link: "/",
  },
  {
    title: "faq",
    link: "/",
  },
];
