import { ProjectCard } from "../../components/cards/project-card/ProjectCard";
import { Footer } from "../../components/layout/footer/Footer";
import { Header } from "../../components/layout/header/Header";
import label_image from "../../public/label_image.png";
import { ProjectInfo } from "./components/project-info/ProjectInfo";
import { Scenario } from "./components/scenario/Scenario";

import { data } from "../homepage/homepage.data";

import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

import { ConnectButton } from '@rainbow-me/rainbowkit';
import Web3 from 'web3';

import { getAccount, watchAccount  } from '@wagmi/core'

export const Project = () => {

  const { param1 } = useParams();
  const projectData = data.find(project => project.project_name === param1);

  return (
    <div className="bg-[#1F252C] min-h-screen flex flex-col text-white">
      <Header />
      <div className="flex-1">
        <div className="w-full">
          <ProjectCard projectData={projectData} />
          <ProjectInfo />
          <Scenario />
        </div>
      </div>
      <Footer />
    </div>
  );
};
