
import { Homepage } from "./pages/homepage/Homepage";
import { Project } from "./pages/project/Project";
import { BrowserRouter, Route, Routes } from "react-router-dom";


import '@rainbow-me/rainbowkit/styles.css';
import { getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import {
  mainnet,
  polygonMumbai
} from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

const { chains, publicClient } = configureChains(
  [mainnet, polygonMumbai],
  [
    alchemyProvider({ apiKey: "BbSU1mc2C5j_8I8TlMn-tPswr3yFuusJ" }), //NOTE: WE WILL HAVE TO REPLACE THIS
    publicProvider()
  ]
);

const { connectors } = getDefaultWallets({
  appName: 'Hideaways Crypto',
  projectId: '063eeea5f4b45a31b3be592f04869063', //NOTE: WE WILL HAVE TO REPLACE THIS
  chains
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient
})

function App() {
  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains}>
        <BrowserRouter>
        <Routes>
        <Route path="/" element={<Homepage />} />

          <Route path="/projects/:param1" element={<Project />} />

        </Routes>
      </BrowserRouter>
      </RainbowKitProvider>
    </WagmiConfig>
  )
  
 
}

export default App;
