import label_image from "../../../public/label_image.png";

import { data } from "../../../pages/homepage/homepage.data";

import { useState, useEffect } from "react";

import { ConnectButton } from '@rainbow-me/rainbowkit';
import Web3 from 'web3';

import { getAccount, watchAccount  } from '@wagmi/core'

import { abi, contractAddress, providerUrl } from '../../../pages/project/assets'

import { prepareWriteContract, writeContract } from '@wagmi/core'

import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";

export const ProjectCard = ({ projectData }) => {
  const [web3, setWeb3] = useState()
  const [contract, setContract] = useState()
  const [minted, setMinted] = useState('-');
  const [supply, setSupply] = useState('-')
  const [price, setPrice] = useState('-')
  const [priceUSD, setPriceUSD] = useState('-')
  const [amount, setAmount] = useState(1)
  const [paused, setPaused] = useState(false)

  const account = getAccount()
  const unwatch = watchAccount((account) => updateConnected(account))
  const [connected, setConnected] = useState(account.isConnected)

  const updateConnected = (account) => {
    if (account.isConnected) {
      setConnected(true)
    }
    else {
      setConnected(false)
    }
  }

  const loadWeb3 = async () => {
    if (projectData) {
      console.log(projectData)

      const web3 = new Web3(providerUrl); //NOTE: WE WILL HAVE TO REPLACE THIS.
      setWeb3(web3)

      const contract = new web3.eth.Contract(abi, contractAddress);
      console.log(contract)
      setContract(contract)

      const sale = await contract.methods.idToSale(projectData.tokenId).call();
      console.log(sale)

      setSupply(sale.totalTokens)
      setMinted(sale.tokensMinted)
      setPaused(sale.paused || sale.refunded)

      var price = parseInt(web3.utils.fromWei((sale.tokenPrice).toString(), 'ether'))
      setPriceUSD(price / 10 ** 8)

  }

  }

  const mint = async () => {
    if (!connected) {
      alert("Connect Your Wallet First!")
    }
    else {

      const contract = new web3.eth.Contract(abi, contractAddress);
      console.log(contract)
      setContract(contract)
      
      if (parseInt(minted) + parseInt(amount) > supply) {
        alert('Exceeds Available Amount!')
        return
      }

      var valueToSend = parseInt(await contract.methods.getPriceETH(projectData.tokenId).call());
      valueToSend = (valueToSend * amount).toString();

      const config  = await prepareWriteContract({
        address: contractAddress,
        abi: abi,
        functionName: 'invest',
        args: [account.address, projectData.tokenId, amount],
        value: valueToSend
      })

      try {
        // Attempt to execute the contract function
        const { hash } = await writeContract(config);
        console.log('Contract function executed successfully');

        await waitForConfirmation(hash, 1)

        await loadWeb3();

        alert("Minted Successfully!");

      } catch (error) {
        // If there is an error during contract execution, handle it here
        console.error('Error executing contract function:', error);
        // You can also show an error message to the user or perform any other error handling logic
      }

    }
  }

  async function waitForConfirmation(transactionHash, confirmations) {
    let receipt;
    while (true) {
      try {
        receipt = await web3.eth.getTransactionReceipt(transactionHash);
        if (receipt && receipt.blockNumber) {
          const currentBlock = await web3.eth.getBlockNumber();
          const confirmationsCount = currentBlock - receipt.blockNumber;
          
          if (confirmationsCount >= confirmations) {
            console.log(`Transaction confirmed with ${confirmations} confirmations.`);
            break;
          } else {
            console.log(`Transaction currently has ${confirmationsCount} confirmations. Waiting...`);
          }
        } else {
          console.log('Transaction is still pending. Waiting...');
        }
      } catch (error) {
        console.error('Error checking transaction receipt:', error);
      }
      
      await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second before checking again
    }
  }

  // Handler to add 1 to the amount
  const addHandler = () => {
    setAmount(amount + 1);
  };

  // Handler to subtract 1 from the amount, but not below 1
  const subtractHandler = () => {
    if (amount > 1) {
      setAmount(amount - 1);
    }
  };

  useEffect(() => {
    if (!web3) {
      loadWeb3()
    }
  }, [])

  return (
    <section className="max-w-container mx-auto px-5">
      <div className="flex md:flex-row flex-col justify-between border border-white/60 h-full bg-[#646464] rounded-xl overflow-hidden ">
        <div className="flex flex-col gap-5 p-4 sm:p-8 w-full justify-end">
          <div className="flex gap-5">
            <div className="px-5 py-1 w-fit text-black rounded-2xl bg-gradient-to-br from-[#BAAF98] to-[#DFDCD1]">
              {projectData.label_1}
            </div>
            <div className="flex items-start gap-2 px-5 py-1 border border-white/30 bg-gradient-to-br from-[#D9D9D9]/70 to-[#D9D9D9]/10 rounded-2xl w-fit">
              <div>
                <img src={label_image} alt="label_image" />
              </div>
              <div>{projectData.label_2}</div>
            </div>
          </div>
          <div className="text-3xl sm:text-5xl font-bold">{projectData.project_name}</div>
          <div className="text-lg sm:text-2xl">{projectData.subtitle}</div>
        </div>
        <div className="md:max-w-[400px]  flex flex-col w-full bg-gradient-to-r from-[#D9D9D9] to-[#DDDDDD]/5">
          <div className="border-b border-[#D9D9D9]/60">
            <div className="grid grid-cols-2 p-4 sm:p-7 items-baseline">
              <div className="text-base sm:text-2xl">Title</div>
              <div className="">DESCRIPTION</div>
            </div>
          </div>
          <div className="border-b border-[#D9D9D9]/60">
            <div className="p-4 sm:p-7 grid grid-cols-2 items-center">
              <div className="flex flex-col gap-1">
                <div>Financed:</div>
                <div className="text-base sm:text-xl font-bold">{(priceUSD * minted).toLocaleString()}$</div>
              </div>
              <div className="flex flex-col gap-1">
                <div>Total:</div>
                <div className="text-base sm:text-xl font-bold">{(priceUSD * supply).toLocaleString()}$</div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="p-4 sm:p-7 grid grid-cols-2 items-center">
              <div className="flex flex-col gap-1">
                <div>Price:</div>
                <div className="text-base sm:text-xl font-bold">{priceUSD} $</div>
              </div>
              <div className="flex flex-col gap-1">
                <div>Invest term:</div>
                <div className="text-base sm:text-xl font-bold">{projectData.invest_term} Months</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3 items-center p-5">
            <div className="flex flex-col items-center gap-2">
              <div>Choose quantity</div>
              <div className="flex gap-2 items-center">
                <div className="rounded-3xl w-11 h-11 border cursor-pointer hover:bg-[#55BBC8]/60 border-white text-5xl leading-10 text-center" onClick={subtractHandler}>
                  -
                </div>
                <div className="rounded-3xl w-20 h-11 border border-white text-2xl leading-10 text-center">
                  {amount}
                </div>
                <div className="rounded-3xl w-11 h-11 border cursor-pointer bg-[#55BBC8] hover:bg-[#55BBC8]/60 border-white text-5xl leading-10 text-center" onClick={addHandler}>
                  +
                </div>
              </div>
            </div>
            {connected && !paused && <button className="bg-[#55BBC8] hover:bg-[#55BBC8]/70 transition-all w-full py-2.5 font-bold rounded-full" onClick={mint}>
              Mint Now
            </button>}
            {paused && <button className="bg-[#55BBC8] hover:bg-[#55BBC8]/70 transition-all w-full py-2.5 font-bold rounded-full" disabled >
              Closed
            </button>}
            {!connected && !paused && <ConnectButton.Custom>
            {({
              account,
              chain,
              openAccountModal,
              openChainModal,
              openConnectModal,
              authenticationStatus,
              mounted,
            }) => {
              // Note: If your app doesn't use authentication, you
              // can remove all 'authenticationStatus' checks
              const ready = mounted && authenticationStatus !== 'loading';
              const connected =
                ready &&
                account &&
                chain &&
                (!authenticationStatus ||
                  authenticationStatus === 'authenticated');
      
              return (
                <div
                  {...(!ready && {
                    'aria-hidden': true,
                    'style': {
                      opacity: 0,
                      pointerEvents: 'none',
                      userSelect: 'none',
                    },
                  })}
                >
                  {(() => {
                    if (!connected) {
                      return (
                        <button className="ml-auto  mr-20 bg-[#55BBC8] hover:bg-[#55BBC8]/70 transition-all w-full py-2.5 font-bold rounded-full" onClick={openConnectModal}>
                          Connect
                        </button> 
                      );
                    }
      
                    if (chain.unsupported) {
                      return (
                        <button onClick={openChainModal} className="connect-wallet__btn border title ml-auto  uppercase mr-5 lg:block hidden border-white px-10 text-2xl py-2 w-fit rounded-full hover:bg-white hover:text-black ">
                          Wrong network
                        </button>
                      );
                    }
      
                    return (
                      <div style={{ display: 'flex', gap: 12 }}>
                        {/*<button
                          onClick={openChainModal}
                          style={{ display: 'flex', alignItems: 'right' }}
                          className="connect-wallet__btn border title ml-auto  uppercase mr-5 lg:block hidden border-white px-10 text-xl py-2 w-fit rounded-full hover:bg-white hover:text-black "
                        >
                          {chain.hasIcon && (
                            <div
                              style={{
                                background: chain.iconBackground,
                                width: 12,
                                height: 12,
                                borderRadius: 999,
                                overflow: 'hidden',
                                marginRight: 4,
                              }}
                            >
                              {chain.iconUrl && (
                                <img
                                  alt={chain.name ?? 'Chain icon'}
                                  src={chain.iconUrl}
                                  style={{ width: 12, height: 12 }}
                                />
                              )}
                            </div>
                          )}
                          {chain.name}
                        </button>*/}
      
                        <button onClick={openAccountModal} className="connect-wallet__btn border title ml-auto  uppercase mr-5 lg:block hidden border-white px-10 text-2xl py-2 w-fit rounded-full hover:bg-white hover:text-black ">
                          {account.displayName}
                          {account.displayBalance
                            ? ` (${account.displayBalance})`
                            : ''}
                          
                        </button>
                      </div>
                    );
                  })()}
                </div>
              );
            }}
          </ConnectButton.Custom>}
          </div>
        </div>
      </div>
    </section>
  );
};
