import { Link } from "react-router-dom";
import label_image from "../../../public/label_image.png";

export const HomepageCard = ({ project_name, subtitle, label_1, label_2, total, invest_term }) => {
  return (
    <div className="bg-[#31373C] rounded-2xl border border-white/30 overflow-hidden h-full min-h-[500px] w-full flex flex-col">
      <div className="p-7 pb-4">
        <div className="text-lg font-extrabold">{project_name}</div>
        <div className="uppercase">{subtitle}</div>
      </div>
      <div className="bg-[#646464] flex-1 px-7 py-5 flex flex-col gap-2">
        <div className="px-5 py-1 w-fit text-black rounded-2xl bg-gradient-to-br from-[#BAAF98] to-[#DFDCD1]">
          {label_1}
        </div>
        <div className="flex items-start gap-2 px-5 py-1 border border-white/30 bg-gradient-to-br from-[#D9D9D9]/70 to-[#D9D9D9]/10 rounded-2xl w-fit">
          <div>
            <img src={label_image} alt="label_image" />
          </div>
          <div>{label_2}</div>
        </div>
      </div>
      <div className="py-5">
        <div className="px-4 flex gap-10">
          <div className="flex flex-col">
            <div>Total Raised:</div>
            <div className="text-lg font-extrabold">{total}$</div>
          </div>
          {/* <div className="flex flex-col">
            <div>Invest term:</div>
            <div className="text-lg font-extrabold">{invest_term} Months</div>
          </div> */}
        </div>
        <div className="px-7">
          <Link
            to={"/projects/" + project_name}
            className="bg-[#55BBC8] w-full h-12 hover:bg-[#55BBC8]/60 transition-all mt-5 rounded-full flex justify-center items-center"
          >
            See Project
          </Link>
        </div>
      </div>
    </div>
  );
};
