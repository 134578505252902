import { HomepageCard } from "../../components/cards/homepage-card/HomepageCard";
import { Footer } from "../../components/layout/footer/Footer";
import { Header } from "../../components/layout/header/Header";
import bg from "../../public/body__bg.png";
import { data } from "./homepage.data";
import { Fade } from "react-reveal";
export const Homepage = () => {
  return (
    <div
      className="bg-[#24323B] min-h-screen flex flex-col text-white"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "repeat-y",
        backgroundSize: "100% 100%",
      }}
    >
      <Header />
      <div className="flex-1">
        <div>
          <div className="max-w-container mx-auto">
            <Fade>
            <div className="">
              <h1 className="text-4xl sm:text-6xl text-center font-extrabold">Hideaways Marketplace</h1>
            </div>
            </Fade>
         
            <div className="my-20 md:my-28 px-5">
              <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-x-12 xl:gap-x-24 gap-y-12">
                {data.map((el) => (
                    <Fade>
 <HomepageCard {...el} />
                    </Fade>
                 
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
