export const data = [
  {
    project_name: "Property Name 1",
    subtitle: "Total Investment Required",
    label_1: "Type of Property",
    label_2: "Location of Property",
    total: 2100,
    invest_term: 0,
    tokenId: 1,
  },
  {
    project_name: "Property Name 2",
    subtitle: "Total Investment Required",
    label_1: "Type of Property",
    label_2: "Location of Property",
    total: 2100,
    invest_term: 0,
    tokenId: 1,
  },
  {
    project_name: "Property Name 3",
    subtitle: "Total Investment Required",
    label_1: "Type of Property",
    label_2: "Location of Property",
    total: 0,
    invest_term: 4,
    tokenId: 1,
  },
  {
    project_name: "Property Name 4",
    subtitle: "Total Investment Required",
    label_1: "Type of Property",
    label_2: "Location of Property",
    total: 0,
    invest_term: 4,
    tokenId: 1,
  },
  {
    project_name: "Property Name 5",
    subtitle: "Total Investment Required",
    label_1: "Type of Property",
    label_2: "Location of Property",
    total: 0,
    invest_term: 4,
    tokenId: 1,
  },
];
