import cn from "classnames";

export const BurgerMenu = ({ isOpen, ...attr }) => {
  return (
    <div {...attr} className="w-10 h-8 flex relative z-50 sm:hidden ">
      <span
        className={cn("w-full h-[2px] absolute top-0 transition-all bg-white", {
          ["rotate-45 -translate-y-1/2 top-1/2"]: isOpen,
          ["rotate-0"]: !isOpen,
        })}
      ></span>
      <span
        className={cn("w-full h-[2px] absolute top-1/2 -translate-y-1/2 transition-all bg-white", {
          ["scale-0"]: isOpen,
          ["scale-1"]: !isOpen,
        })}
      ></span>
      <span
        className={cn("w-full h-[2px] absolute bottom-0 transition-all bg-white", {
          ["-rotate-45 translate-y-1/2 bottom-1/2"]: isOpen,
          ["rotate-0"]: !isOpen,
        })}
      ></span>
    </div>
  );
};
