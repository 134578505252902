import bg from "../../../../public/bg_01.png";
import paly_btn from "../../../../public/play__btn.png";
import card_01 from "../../../../public/card_01.png";
import card_02 from "../../../../public/card_02.png";
import bg_03 from "../../../../public/bg_03.png";
import bg_02 from "../../../../public/bg_02.png";
import { Fade } from "react-reveal";

export const ProjectInfo = () => {
  return (
    <section>
      <div
        style={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
        }}
      >
        <div className="max-w-container mx-auto px-5 py-20">
        <Fade left>
          <div className="text-5xl font-bold mb-20">Project Information</div>
          </Fade>
          <div className="w-full aspect-video md:aspect-[16/5] bg-[#646464] rounded-2xl flex justify-center items-center">
            <button className="hover:opacity-50 transition-all">
              <img src={paly_btn} alt="paly_btn" />
            </button>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url(${bg_02})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 80%",
          backgroundPosition: "center bottom",
        }}
        className="relative"
      >
        <img className="absolute top-0 left-0 w-96" src={bg_03} alt="bg_03" />
        <div className="max-w-container mx-auto px-5 py-20 relative flex flex-col gap-10">
          <div className="flex lg:flex-row flex-col-reverse items-center">
            <div className="w-full p-0 sm:p-10 lg:p-16">
            <Fade left>
              <div className="text-3xl sm:text-5xl font-extrabold mb-5">
                Lorem ipsum dolor sit amet, consectetur adipiscing
              </div>
              </Fade>
              <div className="flex flex-col gap-3">
                <Fade left>
                <p>
                  magna aliqua. Varius vel pharetra vel turpis nunc. Fermentum leo vel orci porta
                  non. At erat pellentesque adipiscing commodo elit at imperdiet. Nisl rhoncus
                  mattis rhoncus urna neque viverra justo. Rutrum quisque non tellus orci ac auctor
                  augue mauris augue. Pulvinar etiam non quam lacus. Nisi scelerisque eu ultrices
                  vitae. Mauris sit amet massa vitae tortor condimentum lacinia quis vel. Et
                  molestie ac feugiat sed lectus vestibulum mattis. Suspendisse in est ante in.
                </p>
                </Fade>
            
              </div>
            </div>
            <Fade right>
            <div className="w-full flex justify-center items-center">
              <img className=" object-contain " src={card_01} alt="card_01" />
            </div>
            </Fade>
          </div>
          <div className="lg:flex-row-reverse flex-col-reverse flex items-center">
            <div className="w-full p-0 sm:p-10 lg:p-16">
              <Fade right>
              <div className="text-3xl sm:text-5xl font-extrabold mb-5">
                Aliquet sagittis id consectetur purus ut faucibus pulvinar
              </div>
              </Fade>
            <Fade>
            <div className="flex flex-col gap-3">
                <p>
                  magna aliqua. Varius vel pharetra vel turpis nunc. Fermentum leo vel orci porta
                  non. At erat pellentesque adipiscing commodo elit at imperdiet. Nisl rhoncus
                  mattis rhoncus urna neque viverra justo. Rutrum quisque non tellus orci ac auctor
                  augue mauris augue. Pulvinar etiam non quam lacus. Nisi scelerisque eu ultrices
                  vitae. Mauris sit amet massa vitae tortor condimentum lacinia quis vel. Et
                  molestie ac feugiat sed lectus vestibulum mattis. Suspendisse in est ante in.
                </p>
                <p>
                  Ipsum dolor sit amet consectetur adipiscing elit ut aliquam purus. Quis vel eros
                  donec ac odio tempor. Donec ac odio tempor orci dapibus ultrices in iaculis nunc.
                  Mi eget mauris pharetra et. Tellus rutrum tellus pellentesque eu tincidunt tortor
                  aliquam. Neque viverra justo nec ultrices dui sapien eget mi proin. Dolor purus
                  non enim praesent elementum facilisis leo vel. Est placerat in egestas erat
                  imperdiet sed. Mauris ultrices eros in cursus turpis massa tincidunt dui. Netus et
                  malesuada fames ac turpis egestas.{" "}
                </p>
              </div>
            </Fade>
           
            </div>
            <Fade left>

            </Fade>   <div className="w-full flex justify-center items-center">
              <img className=" object-contain " src={card_02} alt="card_01" />
            </div>
         
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 justify-center px-5 place-content-center lg:grid-cols-4 gap-11 max-w-container mx-auto items-end">
        <div className="w-full h-full flex justify-center items-end">
          <div className="max-w-xs w-full h-96 bg-[#646464] rounded-3xl"></div>
        </div>
        <div className="w-full h-full flex justify-center items-end">
          <div className="max-w-xs w-full h-60 bg-[#646464] rounded-3xl"></div>
        </div>
        <div className="w-full h-full flex justify-center items-end">
          <div className="max-w-xs w-full h-60 bg-[#646464] rounded-3xl"></div>
        </div>
        <div className="w-full h-full flex justify-center items-end">
          <div className="max-w-xs w-full h-60 bg-[#646464] rounded-3xl"></div>
        </div>
      </div>
    </section>
  );
};
